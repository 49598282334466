const removeEmptyValues = <T extends object>(obj: T): Partial<T> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      // Type-safe check for valid keys
      if (key in obj) {
        acc[key as keyof T] = value;
      }
    }
    return acc;
  }, {} as Partial<T>);
};

export default removeEmptyValues;
